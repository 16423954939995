<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="routes" :search="search" dense>
        <template v-slot:item.action="{item}">
          <TheToolTip label="Edit">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remove">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>

      <TheDialog v-model="dialog" title="Routes">
        <template>
          <TheInput label="Name" v-model="route.name" :error="error" css="my-2" />
          <TheInput label="Url" v-model="route.url" :error="error" css="my-2" />
        </template>
        <template v-slot:actions="">
          <v-btn small depressed color="primary" class="mt-2" dark @click="resolveForm()">
            <v-icon small class="mr-2" v-if="!edit">mdi-plus</v-icon>
            <span v-if="!edit">Salvar</span>
            <span v-if="edit">Atualizar</span>
          </v-btn>          
        </template>
      </TheDialog>
    </v-card>
    
  </v-container>
</template>

<script>
import axios from 'axios'
import {baseApiUrl, showError} from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheInput from '@/components/TheInput'
// import TheButton from '@/components/TheButton'
import TheToolTip from '@/components/TheToolTip'

export default {
  name: 'Route',
  components: {
    TheTitle,
    TheDialog,
    TheInput,
    TheToolTip,
    // TheButton,
  },
  data: () => ({
    title: 'Routes',
    text: 'Adicionar',
    // btnIcon: 'mdi-plus',
    pageIcon: 'mdi mdi-routes',
    dialog: false,
    search: null,
    route: {},
    header: [
      {text: 'Codigo', value: 'id', width: '10%'},
      {text: 'Nome', value: 'name'},
      {text: 'Rota', value: 'url'},
      {text: 'Ações', value: 'action', width: '10%'},
    ],
    verbs: ['get', 'post', 'put', 'delete'],
    routes: [],
    error: false,
    edit: false,
  }),
  methods: {
    loadData() {
      const url = `${baseApiUrl}/route`
      axios
        .get(url)
        .then((res) => {
          this.routes = res.data
        })
        .catch(showError)
    },
    dialogSave() {
      this.route = {}
      this.edit = false
      this.dialog = true
    },
    dialogUpdate(item) {
      this.route = item
      this.edit = true
      this.dialog = true
    },
    resolveForm() {
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.error = false
      const url = `${baseApiUrl}/route`
      const routes = this.route
      axios
        .post(url, routes)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.route.id
      const url = `${baseApiUrl}/route/${id}`
      const routes = this.route
      delete routes.id
      delete routes.type
      delete routes.icon
      delete routes.createdAt
      delete routes.updatedAt
      delete routes.deletedAt
      axios
        .put(url, routes)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/route/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>