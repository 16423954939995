<template>
  <v-text-field
    v-model="value2"
    outlined
    dense
    tile
    color="primary"
    :append-icon="icon"
    :label="label"
    :class="css"
    :type="type"
    :disabled="disabled"
    :error="error"
    :rules="rules"
    @input="update()"
  ></v-text-field>
</template>

<script>
// import { mask } from 'vue-the-mask'
export default {
  name: 'TheInput',
  data: () => ({
    value2: ''
  }),
  props: {
    value: null,
    icon: String,
    css: {
      type: String,
      // default: 'my-2',
    },
    label: {
      type: String,
      default: '',
    },
    type: String,
    disabled: Boolean,
    error: Boolean,
    rules: Array,
    placeholder: String,
  },
  methods: {
    update() {
      this.$emit('input', this.value2)
    },
  },
  mounted() {
    this.value2 = this.value
  },
  watch:{
    value(){
      this.value2 = this.value
    }
  }
}
</script>

<style></style>
